import React from "react";
import { Link } from "gatsby";

import { bookingInfoWrapper } from "../../css/booking-template.module.css";

const BookingInfoPanel = () => {
  return (
    <div className={bookingInfoWrapper}>
      <h3>
        Our volunteer committee usually responds to booking requests within 24
        hours
      </h3>
      <p>
        <strong>
          Can't choose a room? View our{" "}
          <Link to="/rooms">full room details</Link>
        </strong>
      </p>
      <h4>Planning a party?</h4>
      <p>A few rules to bear in mind:</p>
      <ul>
        <li>Self-run bars are not available for parties</li>
        <li>
          We can only accommodate parties for those aged under 11 or over 21
        </li>
        <li>All parties are at the committee's discretion</li>
      </ul>
    </div>
  );
};

export default BookingInfoPanel;
