import React, { useContext, useEffect, useState } from "react";
import { BookingContext } from "../../contexts/BookingContext";
import RoomLegend from "../rooms/roomLegend";

import { calendar } from "../../css/booking-template.module.css";

const AvailabilityCalendar = () => {
  const [bookingValues] = useContext(BookingContext);
  const [room, setRoom] = useState();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      bookingValues.bookingRoom && setRoom(bookingValues.bookingRoom);
    }
    return () => {
      isCancelled = true;
    };
  }, [bookingValues.bookingRoom]);

  return (
    <React.Fragment>
      <div className={calendar}>
        <div>
          <iframe
            title="Room availability calendar"
            src={room && room.availabilityCalendarLink}
          ></iframe>
        </div>
      </div>
      <RoomLegend />
    </React.Fragment>
  );
};

export default AvailabilityCalendar;
