import React, { useState, useContext } from "react";
import { navigate } from "gatsby";
import DateTime from "react-datetime";
import moment from "moment";

import { BookingContext } from "../../contexts/BookingContext";

import {
  selectDateTimeWrapper,
  selectDateWrapper,
} from "../../css/booking-template.module.css";

const SelectDateTime = () => {
  const [bookingValues, setBookingValues] = useContext(BookingContext);
  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();

  const minDate = moment(new Date()).add(1, "days").set({ hour: 9, minute: 0 });
  const maxDate = moment(minDate).add(1, "year");
  const timeCon = {
    hours: { min: 0, max: 23 },
    minutes: { step: 30 },
  };
  const endCon = {
    hours: { mins: 0, max: 23 },
    minutes: { step: 30 },
  };

  const validFrom = (current) => {
    const priorDate = minDate.subtract(2, "day");
    return current.isAfter(priorDate) && current.isBefore(maxDate);
  };
  const validTo = (current) => {
    return current.isAfter(
      moment(startDateTime).add(30, "minutes").subtract(1, "day")
    );
  };

  const handleChangeStart = (time) => {
    if (time._isValid) {
      setStartDateTime(time._d);
      if (time._d >= endDateTime) {
        let newEndTime = moment(time._d).add(30, "minutes");
        setEndDateTime(newEndTime._d);
      }
    }
  };

  const handleChangeEnd = (time) => {
    if (time._isValid) {
      setEndDateTime(time._d);
      if (time._d <= startDateTime) {
        let newStartTime = moment(time._d).subtract(30, "minutes");
        setStartDateTime(newStartTime._d);
      }
    }
  };

  const handleNavigate = () => {
    const bookingDate = {
      start: startDateTime,
      end: endDateTime,
    };
    setBookingValues({ ...bookingValues, bookingDate });
    navigate("facilities");
  };

  const initialiseEndDate = (endDateTime) => {
    if (endDateTime === undefined) {
      setEndDateTime(moment(minDate).add(30, "minutes"));
    }
  };

  return (
    <div className={selectDateWrapper}>
      <h2>Select date and time</h2>
      <div className={selectDateTimeWrapper}>
        <div>
          <label htmlFor="startDate">Start date and time</label>
          <DateTime
            defaultValue={minDate}
            viewDate={minDate}
            name="startDate"
            value={startDateTime}
            onChange={handleChangeStart}
            onOpen={initialiseEndDate(endDateTime)}
            isValidDate={validFrom}
            timeConstraints={timeCon}
            dateFormat={"DD/MM/YYYY"}
            timeFormat={"HH:mm"}
            inputProps={{ required: true, readOnly: true }}
          />
        </div>

        <div>
          <label htmlFor="toTime">End time</label>
          <DateTime
            name="endTime"
            value={endDateTime}
            onChange={handleChangeEnd}
            timeConstraints={endCon}
            isValidDate={validTo}
            dateFormat={"DD/MM/YYYY"}
            timeFormat={"HH:mm"}
            defaultValue={moment(minDate).add(30, "minutes")}
            viewDate={moment(minDate).add(30, "minutes")}
            inputProps={{ readOnly: true }}
          />
        </div>

        <button
          onClick={handleNavigate}
          disabled={!startDateTime || !endDateTime}
          className="btn-green"
        >
          Save and continue
        </button>
      </div>
      <div>
        Use the drop-down calendar to select your date, then click the time
        below the calendar to change it.
      </div>
      <div>
        Any issues booking? Please feel free to send an enquiry via our{" "}
        <a href="/contact">contact form</a>.
      </div>
    </div>
  );
};

export default SelectDateTime;
