import React from "react";

const RoomLegend = () => {
  return (
    <table class="room-legend">
      <caption>Room abbreviations</caption>
      <tbody>
        <tr>
          <th>MH</th>
          <td>Main Hall</td>
        </tr>
        <tr>
          <th>FR</th>
          <td>Function Room</td>
        </tr>
        <tr>
          <th>SH</th>
          <td>Snooker Hall</td>
        </tr>
        <tr>
          <th>AR</th>
          <td>Activity Room</td>
        </tr>
        <tr>
          <th>CR</th>
          <td>Committee Room</td>
        </tr>
      </tbody>
    </table>
  );
};

export default RoomLegend;
