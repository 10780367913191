import React, { useContext, useEffect, useState } from "react";
import { BookingContext } from "../../contexts/BookingContext";
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import {
  roomFeatures,
  selectedRoomDetails,
  selectedRoomImage,
  selectedRoomWrapper,
} from "../../css/booking-template.module.css";

const SelectedRoom = () => {
  const [bookingValues] = useContext(BookingContext);
  const [room, setRoom] = useState();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      bookingValues.bookingRoom && setRoom(bookingValues.bookingRoom);
    }
    return () => {
      isCancelled = true;
    };
  }, [bookingValues.bookingRoom]);

  return (
    <div className={selectedRoomWrapper}>
      <div className={selectedRoomImage}>
        {room && (
          <GatsbyImage
            image={room.roomImage.childImageSharp.gatsbyImageData}
            alt={room.RoomTitle}
            imgStyle={{ objectFit: "cover" }}
            style={{ width: "100%" }}
          />
        )}
      </div>
      <div className={selectedRoomDetails}>
        <h3>{room && room.RoomTitle}</h3>
        <div className={roomFeatures}>
          <ul>
            {room &&
              room.feature.roomFeatures.map((item, i) => (
                <li key={i}>
                  <div>
                    <GatsbyImage
                      image={item.featureImage.childImageSharp.gatsbyImageData}
                      alt={item.feature}
                      style={{ marginRight: "0.4rem" }}
                    />
                    <div>{item.feature}</div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
        <Link to={`/rooms/${room && room.slug}`}>Find out more...</Link>
      </div>
    </div>
  );
};

export default SelectedRoom;
