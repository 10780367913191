import React, { useContext, useEffect } from "react";
import { graphql } from "gatsby";

import { BookingContext } from "../contexts/BookingContext";

import BookingRoomHeader from "../components/booking/bookingRoomHeader";
import SelectDateTime from "../components/booking/selectDateTime";
import SelectedRoom from "../components/booking/selectedRoom";
import BookingInfoPanel from "../components/booking/bookingInfoPanel";
import AvailabilityCalendar from "../components/booking/availabilityCalendar";

import { bookingWrapper } from "../css/booking-template.module.css";

const BookRoom = ({ data }) => {
  const [, setBookingValues] = useContext(BookingContext);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      const bookingRoom = data.strapiRoom;

      setBookingValues({ bookingRoom });
    }

    return () => {
      isCancelled = true;
    };
  }, [data, setBookingValues]);

  return (
    <>
      <div className={bookingWrapper}>
        <BookingRoomHeader />
        <SelectDateTime />
        <AvailabilityCalendar />
        <SelectedRoom />
        <BookingInfoPanel />
      </div>
    </>
  );
};

export default BookRoom;

export const query = graphql`
  query bookRoom($slug: String!) {
    strapiRoom(slug: { eq: $slug }, bookOnline: { eq: true }) {
      Facilities {
        facilities {
          cost
          description
          name
        }
      }
      RoomTitle
      altBookingInfo
      bookOnline
      bookingStatus
      feature {
        id
        roomFeatures {
          feature
          featureImageWhite {
            childImageSharp {
              gatsbyImageData(width: 30, height: 30, layout: FIXED)
            }
          }
          featureImage {
            childImageSharp {
              gatsbyImageData(width: 30, height: 30, layout: FIXED)
            }
          }
        }
      }
      availabilityCalendarLink
      floorPlanLink
      hireAgreementLink
      id
      prices {
        cost
        bookingType {
          name
          description
        }
      }
      roomImage {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
      roomStatus
      roomsRichText
      roomstext
      slug
    }
  }
`;
